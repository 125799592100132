import React from 'react'
import styled from 'styled-components'
import { rem } from 'polished'
import cn from 'clsx'

import { destinationNameToUrlTransform } from 'lib/string/removeSpaceUtils'
import Image from 'components/Common/Image'
import { TILE_WIDTH, TILE_HEIGHT, TILE_PADDING } from './constants'
import ClickableLink from 'components/Common/Clickable/ClickableLink'
import Caption from 'components/Luxkit/Typography/Caption'
import Heading from 'components/Luxkit/Typography/Heading'
import { mediaHoverable } from 'lib/theme/mediaQueries'

const Wrapper = styled.div`
  height: ${rem(TILE_HEIGHT + TILE_PADDING)};
  width: ${rem(TILE_WIDTH)};
  padding-top: ${rem(TILE_PADDING)};
`

const Tile = styled.div`
  position: relative;
  height: ${rem(TILE_HEIGHT)};
  border-radius: ${props => props.theme.borderRadius.S};
  transform: translateY(0) translateZ(0);
  transition: transform 0.3s, box-shadow 0.3s;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  overflow: hidden;

  ${mediaHoverable} {
    *:hover > &.hover-effect {
      transform: translateY(${rem(-8)});
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.34);
    }
  }
  a:active &.hover-effect {
    transform: translateY(${rem(-8)});
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.34);
  }
`

const BgImage = styled(Image)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  /* Fix for weird issue that caused the images to sometimes not render */
  min-height: 1px;
`

const Content = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: ${rem(24)} ${rem(16)};
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  color: ${props => props.theme.palette.neutral.default.eight};

  &.has-image {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 51.56%, rgba(0, 0, 0, 0.9) 100%);

    a:active & {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.12), rgba(0, 0, 0, 0.12)), linear-gradient(180deg, rgba(0, 0, 0, 0) 51.56%, rgba(0, 0, 0, 0.9) 100%);
    }
  }

  a:active &:not(.has-image) {
    background: rgba(0, 0, 0, 0.12);
  }
`

interface Props {
  destination: App.PopularDestination;
  enableHoverEffect?: boolean;
}

const stopPropagation = (e: React.TransitionEvent<HTMLDivElement>) => e.stopPropagation()

function DestinationTile({ destination, enableHoverEffect = false }: Props) {
  const { name, secondaryText, fullName, imageId } = destination
  const url = `/hotels/location/${destinationNameToUrlTransform(fullName)}`
  const alt = `Hotels in ${name}, ${secondaryText}`

  return <Wrapper>
    <ClickableLink to={url} preventSwipeClick>
      <Tile className={cn({ 'hover-effect': enableHoverEffect })} onTransitionEnd={stopPropagation}>
        {imageId &&
          <BgImage
            id={imageId}
            width={TILE_WIDTH}
            aspectRatio={`${TILE_WIDTH}:${TILE_HEIGHT}`}
            format="jpg"
            quality="good"
            loading="lazy"
            alt={alt}
          />
        }
        {!imageId &&
          <BgImage
            id="k9ube2ivisuqow7m1pqk"
            format="png"
            quality="good"
            loading="lazy"
            alt={alt}
          />
        }
        <Content className={cn({ 'has-image': imageId })}>
          <Caption variant="medium">{secondaryText}</Caption>
          <Heading colour="neutral-eight" as="h5" variant="heading5" wrap="truncate">{name}</Heading>
        </Content>
      </Tile>
    </ClickableLink>
  </Wrapper>
}

export default DestinationTile
